
import { Component, Vue } from 'vue-property-decorator'
import { ProductTypeDetail } from '@/types/product'
import { FileInfo } from '@/types/common'

@Component({
  name: 'ProductTypeDetail',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private title = '产品类型详情'
  private info: ProductTypeDetail = {
    productTypeName: '',
    synopsis: '',
    resourceList: []
  }

  get productTypeId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.productTypeId) {
      this.getDetail()
    }
  }

  // 产品类型详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectProductTypeByProductTypeId, {
      productTypeId: this.productTypeId
    }).then((res) => {
      this.info = res
    })
  }
}
